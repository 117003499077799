import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../service/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // Verifica si la solicitud debe ser ignorada
        if (
          request.headers.has('Skip-Interceptor') &&
          request.headers.get('Skip-Interceptor') === 'true'
        ) {
          // Clona la solicitud sin el encabezado 'Skip-Interceptor' para evitar efectos secundarios
          const newReq = request.clone({
            headers: request.headers.delete('Skip-Interceptor'),
          });
          return next.handle(newReq);
        }

        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          location.reload();
        }

        let errorMessage = 'Error desconocido';

        if (err.status === 404) {
          errorMessage = 'No se encontró el recurso solicitado';
        }

        // A veces se hace peticion con tipo ArrayBuffer porque si se especifica que
        // espera json no recibe bien los blob, y si se especifica que espera blob
        // no recibe bien los json, por lo que se hace la peticion con tipo ArrayBuffer
        if (err.error instanceof ArrayBuffer) {
          try {
            const text = new TextDecoder().decode(err.error);
            const json = JSON.parse(text);
            console.log(json);
            errorMessage =
              json?.error.message ||
              json?.statusText ||
              json?.error.error ||
              json?.error;
          } catch (e) {
            errorMessage = 'Error desconocido al procesar la respuesta';
          }
        } else {
          errorMessage =
            err.error?.message ||
            err.error?.error ||
            err?.statusText ||
            err?.error;
        }

        return throwError(() => new Error(errorMessage));
      })
    );
  }
}
